<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>属性管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">属性名称：</div>
                <el-input v-model="search.attributesName" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="attributesClassInfoAdd" @click="goAdd">新增属性</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" prop="attributesName" label="属性名称"></el-table-column>
            <el-table-column align="center" prop="createDate" label="创建时间"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="attributesClassInfoUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="attributesClassInfoDel" @click="del(scope.row.id)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <add-attributes-class-info :data="upData" :type="type" :visible.sync="dialogVisible" @getData="getData"/>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
import AddAttributesClassInfo from "./add";
export default {
  components: {AddAttributesClassInfo},
  data() {
    return {
      search: {
        attributesName: "",
      },
      type: "",
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        attributesName: ""
      },
      rules: {
        attributesName: [{required: true, message: "请输入属性名称", trigger: "blur"}]
      },
      attributesClassInfoAdd: false,
      attributesClassInfoDel: false,
      attributesClassInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for(let key in filterData) {
        if(filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getAttributesClassInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "attributesClassInfo:add") {
          _this.attributesClassInfoAdd = true;
        }
        if (v == "attributesClassInfo:del") {
          _this.attributesClassInfoDel = true;
        }
        if (v == "attributesClassInfo:upd") {
          _this.attributesClassInfoUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 添加关联属性值
    addSmall() {

    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("添加成功");
                _this.handleClose();
                _this.getData();
              }
            });
          } else {
            _this.$api.editAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("修改成功");
                _this.getData();
                _this.handleClose();
              }
            });
          }
        }else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        attributesName: ""
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search.attributesName = "";
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        attributesName: ""
      }
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delAttributesClassInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
.add-small {
  font-size: 30px;
  color: #ef7272;
  vertical-align: middle;
}
</style>
