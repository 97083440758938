<template>
  <el-dialog
      :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
  >
    <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
      <el-form-item label="属性名称：" prop="attributesName">
        <span v-if="type === 'details'">{{ upData.attributesName }}</span>
        <el-input v-else v-model="upData.attributesName" placeholder="请输入属性名称"></el-input>
      </el-form-item>
    </el-form>
    <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
    <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "add-attributesClassInfo",
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.dialogVisible = val;
      }
    }
  },
  data() {
    return {
      type: "",
      dialogVisible: false,
      btnLoading: false,
      formDataReady: true,
      upData: {
        attributesName: ""
      },
      rules: {
        attributesName: [{required: true, message: "请输入属性名称", trigger: "blur"}]
      },
    }
  },
  methods: {
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("添加成功");
                _this.handleClose();
                _this.$emit('getData',res.data.data);
              }
            });
          } else {
            _this.$api.editAttributesClassInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.btnLoading = false;
                _this.$message.success("修改成功");
                _this.handleClose();
                this.$emit('update:visible', false);
              }
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        attributesName: ""
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
      _this.$emit('update:visible', false);
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
  }
}
</script>

<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

</style>
